document.addEventListener('musickitloaded', () => {
  const button = document.getElementById('apple_button');
  const form = document.getElementById('apple_form');
  const input = form.querySelector('input');

  const sm = new SMEAppleMusic({
    ae: {
      ae: '08437736b7852d630abdb0b0816ead312d19026cc1fedc2475ddc4cfda6efb86',
      brand_id: '3443541',
      segment_id: '839871',
      activities: '{"actions":{"presave":31020},"mailing_list_optins":{"a0S2400000MLez1EAD":31024}}'
    },
    am: {
      dev_token: '',
      save_mode: 'library',
      custom_playlist_name: '',
      resources: {
        playlists: [],
        albums: ['https://music.apple.com/us/album/nibiru/1489407209']
      }
    },
    sf: {
      form: '53913',
      default_mailing_list: 'a0S2400000MLez1EAD'
    },
    smf: {
      campaign_id: '53913',
      campaign_key: '4f666a803239e31fb7de0be56e9abdf2'
    }
  });

  button.addEventListener('click', event => {
    event.preventDefault();
    form.classList.add('slide--open');
    input.focus();
  });

  form.addEventListener('submit', event => {
    event.preventDefault();
    sm
      .doActions(input.value, true)
      .then(result => {
        if (result.state !== 'success') {
          throw new Error('error');
        }
        const id = encodeURIComponent(btoa(sm.user_email));
        window.location.assign('/auth.html?state=thank-you&apple_user=' + id);
      })
      .catch(() => alert('Ha ocurrido un error...'));
  });
});
